import React, { useEffect, useState } from 'react';

const ErrorPage = ({ message }) => {
  const [secondsLeft, setSecondsLeft] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsLeft((prevSeconds) => prevSeconds - 1);
    }, 1000);

    const reloadTimer = setTimeout(() => {
      window.location.reload();
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(reloadTimer);
    };
  }, []);

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      color: '#FF4242',
      fontSize: '3rem',
      fontWeight: 'bold',
      textAlign: 'center',
    }}>
      {message} ({secondsLeft})
    </div>
  );
};

export default ErrorPage;

import { getEnabledFeatures } from '../modules/layout/selectors';
import { AppFeature } from '../utils/device';
import { useAppSelector } from '../utils/hooks';
import ModeSelector from './ModeSelector';
import Tickets from './Tickets';
import ErrorPage from './ErrorPage';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const enabledFeatures = useAppSelector((state) => getEnabledFeatures(state.layout));
  const { t } = useTranslation(['common']);

  return (
    <>
      {(!enabledFeatures || enabledFeatures.length === 0) ? (
        <ErrorPage message={t('common:warnings.noEnabledFeatures')} />
      ) : (
        enabledFeatures.length === 1 && enabledFeatures[0] === AppFeature.TicketSale ? (
          <Tickets></Tickets>
        ) : (
          <ModeSelector></ModeSelector>
        )
      )}
    </>
  );
};

export default Home;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAsyncThunkWithRetry } from '../../utils/asyncThunkWithRetry';
import axiosInstance from '../../utils/axios';
import { getAppDetails } from '../../utils/terminal';
import actionTypes from './actionTypes';
import { ConfirmPaymentRequest, CreateOrderRequest, OrderStatus } from './models';

export const createOrder = createAsyncThunkWithRetry(
  actionTypes.CREATE_ORDER,
  async (createPaymentRequest: CreateOrderRequest): Promise<OrderStatus> => {
    try {
      const data = JSON.stringify(createPaymentRequest);
      console.log('startPayment: ' + data);
      //TODO: take app id from API
      const appDetails = await getAppDetails();
      var response = await axiosInstance.post<OrderStatus>(`/v1/paybox/${appDetails.id}/tickets`, data);
      return response.data;
    } catch (e) {
      console.error(JSON.stringify(e));
      throw e;
    }
  },
);

export const getOrder = createAsyncThunkWithRetry(actionTypes.GET_ORDER, async (ticketUuid: string): Promise<OrderStatus> => {
  try {
    const appDetails = await getAppDetails();
    const response = await axiosInstance.get<OrderStatus>(`/v1/paybox/${appDetails.id}/tickets/${ticketUuid}/order`);
    return response.data;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
});

async function retryPaymentRequest(requestFn: Function, retries: number, delay: number) {
  for (let i = 1; i <= retries; i++) {
    try {
      return await requestFn();
    } catch (error) {
      console.log(`Retry no ${i} with error: ${JSON.stringify(error)}`);
      if (i > retries) {
        throw error;
      }
      await new Promise(res => setTimeout(res, delay));
    }
  }
}

export const confirmPayment = createAsyncThunk(
  actionTypes.CONFIRM_PAYMENT,
  async (createPaymentRequest: ConfirmPaymentRequest): Promise<boolean> => {
    try {
      const appDetails = await getAppDetails();
      const data = JSON.stringify(createPaymentRequest);

      const sendRequest = async () => {
        return await axiosInstance.post(`/v1/paybox/${appDetails.id}/tickets/pay`, data);
      };

      await retryPaymentRequest(sendRequest, 5, 2000);

      return true;
    } catch (e) {
      console.error(JSON.stringify(e));
      return false;
    }
  },
);

// export const startAndConfirmPayment = createAsyncThunkWithRetry(actionTypes.START_AND_CONFIRM, async (requestTickets: RequestTicket[], thunkApi) => {
//   var result = await thunkApi.dispatch(startPayment(requestTickets));

//   await thunkApi.dispatch(confirmPayment(result.payload.orderId));
// });

import { getAppDetails } from '../../utils/terminal';
import axiosInstance from '../../utils/axios';
import { Category, Product, ProductsRequestQuery } from './models';
import actionTypes from './actionTypes';
import { createAsyncThunkWithRetry } from '../../utils/asyncThunkWithRetry';

export const fetchProducts = createAsyncThunkWithRetry(actionTypes.FETCH_PRODUCTS, async (query?: ProductsRequestQuery): Promise<Product[]> => {
  try {
    const appDetails = await getAppDetails();
    let url = `/v1/paybox/${appDetails.id}/products`;
    const params = new Array<string>();
    if (query) {
      if (query.categoryId.length > 0) {
        params.push(`categoryId=${query.categoryId}`);
      }
      if (query.search.length > 0) {
        params.push(`search=${query.search}`);
      }
      if (params.length > 0) {
        url += '&';
      }
    }

    params.forEach((param) => (url += param));

    const response = await axiosInstance.get<Product[]>(url);
    return response.data;
  } catch (error) {
    console.error(JSON.stringify(error));
    return [] as Product[];
  }
});

export const fetchProduct = createAsyncThunkWithRetry(actionTypes.FETCH_PRODUCT, async (productId: string): Promise<Product | null> => {
  try {
    const appDetails = await getAppDetails();
    let url = `/v1/paybox/${appDetails.id}/products/${productId}`;

    const response = await axiosInstance.get<Product>(url);
    return response.data;
  } catch (error) {
    console.error(JSON.stringify(error));
    return null;
  }
});

export const fetchCategories = createAsyncThunkWithRetry(actionTypes.FETCH_CATEGORIES, async (): Promise<Category[]> => {
  try {
    const appDetails = await getAppDetails();
    let url = `/v1/paybox/${appDetails.id}/products/categories`;

    const response = await axiosInstance.get<Category[]>(url);
    return response.data;
  } catch (error) {
    console.error(JSON.stringify(error));
    return [];
  }
});

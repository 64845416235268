import actionTypes from './actionTypes';
import { Operator } from './models';
import axiosInstance from '../../utils/axios';
import { getAppDetails } from '../../utils/terminal';
import { createAsyncThunkWithRetry } from '../../utils/asyncThunkWithRetry';

export const loginOperator = createAsyncThunkWithRetry(actionTypes.OPERATOR_LOGIN, async (authorizationCode: string): Promise<Operator> => {
  try {
    console.log('start logging Operator');
    const appDetails = await getAppDetails();
    const response = await axiosInstance.post<Operator>(`/v1/paybox/${appDetails.id}/operator-login`, { authorizationCode: authorizationCode });
    return response.data;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
});

import { AppDetails, getAppInfo } from '../../utils/device';
import actionTypes from './actionTypes';
import { setOperatorLoginEnabled } from '../account/slice';
import { createAsyncThunkWithRetryAndApi } from '../../utils/asyncThunkWithRetry';

export const getAppSettings = createAsyncThunkWithRetryAndApi(actionTypes.GET_SETTINGS, async (_, thunkAPI): Promise<AppDetails> => {
  try {
    console.log('start getting AppSettings');

    const appInfo = await getAppInfo();
    thunkAPI.dispatch(setOperatorLoginEnabled(appInfo.operatorLoginEnabled));

    return appInfo;
  } catch (e) {
    console.error(JSON.stringify(e));
    throw e;
  }
});

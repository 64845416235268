/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Paper, Stack, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import React, { Suspense, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PaymentWrapper from '../components/PaymentWrapper';
import { SummaryText } from '../components/styled-components';
import { BasketTicket } from '../modules/basket/models';
import { addOrUpdateBasketTicket } from '../modules/basket/slice';
import { fetchTickets } from '../modules/tickets/operations';
import { getParkingTickets, getTickets } from '../modules/tickets/selectors';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import PrinterWarningDialog from './summary/PrinterWarningDialog';
import { v4 as uuidv4 } from 'uuid';
import PrinterConnectingDialog from './summary/PrinterConnectingDialog';
import useValidateSettingsAndPay, { ConnectingStatus } from '../hooks/useValidateSettingsAndPay';
import HardwareOperationDialog from './summary/HardwareOperationDialog';

const PlateText = styled(Typography)({ textAlign: 'center', fontWeight: 700, fontSize: '3rem', color: '#6260A8' });
const TicketTypeText = styled(Typography)({ fontSize: '1.5rem', color: '#5E5E5E' });
const PriceText = styled(Typography)({ fontWeight: 600, fontSize: '2.2rem', color: '#5E5E5E' });

const ParkingSummary = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const parkingTickets = useAppSelector((s) => getParkingTickets(s.tickets));
  const { plate, variantId } = useParams();
  const [validateSettingsAndPay, printerConnectingState, hardwareOperationInProgress, _, resetState] = useValidateSettingsAndPay();
  const tickets = useAppSelector((s) => getTickets(s.tickets));

  useMemo(() => {
    if (variantId) {
      console.log('Fetch tickets');
      dispatch(fetchTickets(tickets));
    }
  }, [variantId]);

  const parkingTicket = useMemo(() => {
    if (parkingTickets && parkingTickets.length > 0) {
      console.log(parkingTickets);
      const pt = parkingTickets?.find((x) => x.variantId === Number(variantId));
      console.log(pt);
      if (pt) {
        const basketTicket = {
          id: uuidv4(),
          amount: pt.price.amount,
          quantity: 1,
          question: {
            answer: plate,
            questionId: pt.question.questionId,
          },
          ticket: pt,
        } as BasketTicket;
        dispatch(addOrUpdateBasketTicket(basketTicket));
        return pt;
      } else {
        console.warn(`Cannot find parking ticket for variant: ${variantId}`);
      }

      return null;
    }

    return null;
  }, [parkingTickets?.length]);

  const handlePaymentClick = async () => {
    if (parkingTicket) {
      console.log('Parking Summary - handle payment');
      await new Promise((resolve) => setTimeout(resolve, 0));

      await validateSettingsAndPay();
    } else {
      //TODO: display user error message
      console.error('Empty parking ticket. Cannot start payment.');
    }
  };

  return (
    <>
      <PaymentWrapper
        title={t('common:summary.summary')}
        onPaymentClick={handlePaymentClick}
        displayCompanyDetailsButton={true}
        isPaymentMethodSelected={true}
      >
        <>
          <Paper>
            <Stack direction="column" sx={{ width: '100%' }}>
              <Stack direction="row" justifyContent="space-between" sx={{ width: '100%', px: 4, my: 2 }}>
                <TicketTypeText>{parkingTicket?.name}</TicketTypeText>
                <PriceText sx={{ alignSelf: 'right' }}>
                  <>
                    {parkingTicket?.price?.amount} {parkingTicket?.price?.currency}
                  </>
                </PriceText>
              </Stack>
              <SummaryText sx={{ px: 4, my: 2 }}>{format(new Date(), 'dd.MM.yyyy')}</SummaryText>
              <Divider sx={{ borderTop: '2px dashed #6260A8', my: 2, width: '100%' }}></Divider>
              <PlateText sx={{ my: 2 }}>{plate}</PlateText>
            </Stack>
          </Paper>
        </>
      </PaymentWrapper>
      <Suspense>
        <PrinterWarningDialog
          open={printerConnectingState === ConnectingStatus.Error}
          handleClose={() => resetState()}
        ></PrinterWarningDialog>
        <PrinterConnectingDialog open={printerConnectingState === ConnectingStatus.Pending} />
        <HardwareOperationDialog open={ hardwareOperationInProgress } />
      </Suspense>
    </>
  );
};

export default ParkingSummary;

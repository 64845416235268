import React, { createContext, useContext, useState, useCallback } from 'react';
import { Alert, Snackbar } from '@mui/material';

type ToastContextType = {
  showError: (message: string) => void;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState('');

  const showError = useCallback((msg: string) => {
    setMessage(msg);
    setOpenError(true);
  }, []);

  const handleClose = () => setOpenError(false);

  return (
    <ToastContext.Provider value={{ showError }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openError}
        onClose={handleClose}
        autoHideDuration={4000}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%', fontSize: '1.25rem', textAlign: 'center' }}>
          {message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GreenButton, GreyButton, VioletButton } from '../../components/styled-components';
import { getBasketSummary, getInvoiceData } from '../../modules/basket/selectors';
import { clearBasket } from '../../modules/basket/slice';
import { getOrderStatus } from '../../modules/payment/selectors';
import { useAppDispatch, useAppReturnLink, useAppSelector } from '../../utils/hooks';
import { printInvoice } from '../../utils/terminal';
import { ConfirmationContent } from './styles';
import WizardSteps from './WizardSteps';
import { PaymentType } from '../../modules/payment/models';
import React, { Suspense, useState } from 'react';
import HardwareOperationDialog from '../summary/HardwareOperationDialog';

const Cash = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const basketSummary = useAppSelector((state) => getBasketSummary(state.basket));
  const paymentStatus = useAppSelector((state) => getOrderStatus(state.payment));
  const invoiceData = useAppSelector((state) => getInvoiceData(state.basket));
  const primaryLink = useAppReturnLink();
  const theme = useTheme();
  const isDownMDScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClosePaymentButtonClick = async () => {
    dispatch(clearBasket());
    navigate(primaryLink);
  };

  const [hardwareOperationInProgress, setHardwareOperationInProgress] = useState(false);

  const handleQRCodeButtonClick = async () => {
    dispatch(clearBasket());
    navigate('/qrcodes');
  };

  const handlePrint = async () => {
    setHardwareOperationInProgress(true);
    await new Promise((resolve) => setTimeout(resolve, 0));

    const printState = await printInvoice(
      paymentStatus?.orderId ?? '',
      paymentStatus?.tickets ?? [],
      paymentStatus?.products ?? [],
      invoiceData?.nip,
      PaymentType.CASH,
    );
    console.log('[Cash] Print status: ' + printState);
    setHardwareOperationInProgress(false);
  };

  return (
    <>
      <WizardSteps activeStep={4}>
        <Stack direction={'column'} justifyContent={'center'} spacing={3}>
          <Box
            component="img"
            src="/images/cash_confirmation.png"
            sx={{ height: isDownMDScreen ? '130px' : '130px', objectFit: 'contain' }}
          ></Box>
          <ConfirmationContent align="center">
            {t('common:payments.payed').toString()}!
            <br />
            {t('common:payments.paymentConfirmed').toString()}
            <br />
            {t('common:payments.paymentAmountText').toString()} {basketSummary.amount.toFixed(2)}{' '}
            {basketSummary.currency}.
          </ConfirmationContent>
          <Stack
            sx={{ width: '100%' }}
            direction={{ xs: 'column', md: 'column' }}
            spacing={3}
            justifyContent={'space-between'}
          >
            <Stack direction="row" spacing={2} justifyContent={'center'}>
              <GreyButton onClick={handleQRCodeButtonClick}>
                <Typography>{t('common:buttons.qrCode')}</Typography>
              </GreyButton>

              <VioletButton onClick={handlePrint}>
                <Typography>{t('common:payments.printAgain')}</Typography>
              </VioletButton>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent={'center'}>
              <GreenButton onClick={handleClosePaymentButtonClick}>
                <Typography>{t('common:buttons.complete')}</Typography>
              </GreenButton>
            </Stack>
          </Stack>
        </Stack>
      </WizardSteps>
      <Suspense>
        <HardwareOperationDialog open={ hardwareOperationInProgress } />
      </Suspense>
    </>
  );
};

export default Cash;

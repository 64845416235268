import { Box, Snackbar, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getBasketTicketSummary } from '../modules/basket/selectors';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { useEffect, useRef, useState } from 'react';
import { fetchTickets } from '../modules/tickets/operations';
import MainTickets from './tickets/MainTickets';
import TodayStatsBar from './tickets/TodayStatsBar';
import { getTickets } from '../modules/tickets/selectors';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import PageHeader from '../components/PageHeader/PageHeader';
import FreeTicketsSection from './tickets/FreeTicketSection';
import BottomNavigationWrapper from './tickets/BottomNavigationWrapper';
import { getSelfCheckout } from "../modules/layout/selectors";
import { RedButton } from '../components/styled-components';
import BottomReturnNavigationWrapper from './tickets/BottomReturnNavigationWrapper';

const NoTicketsText = styled(Typography)({
  fontWeight: 700,
  fontSize: '2rem',
});

const Tickets = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isMDScreen = useMediaQuery(theme.breakpoints.up('md'));
  const tickets = useAppSelector((s) => getTickets(s.tickets));
  const selfCheckout = useAppSelector((s) => getSelfCheckout(s.layout));

  const ticketsRef = useRef(tickets);
  useEffect(() => {
    ticketsRef.current = tickets;
  }, [tickets]);

  useEffect(() => {
    dispatch(fetchTickets(ticketsRef.current));
    const interval = setInterval(() => {
      console.log('Tickets - scheduled - fetch tickets');
      dispatch(fetchTickets(ticketsRef.current));
    }, 20 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ py: 2 }}>
      {!isMDScreen && !selfCheckout && <TodayStatsBar></TodayStatsBar>}
      {tickets.length === 0 && (
        <Box
          sx={{
            p: 2,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            my: 4,
          }}
        >
          <NoTicketsText align="center">{t('common:tickets.noTickets').toString()}</NoTicketsText>
        </Box>
      )}
      {tickets.length > 0 && <PageHeader title={t('common:tickets.mainTicketWindowHeader')}></PageHeader>}
      <MainTickets></MainTickets>
      <FreeTicketsSection></FreeTicketsSection>
      {tickets.length > 0 && <BottomNavigationWrapper returnUrl="/" />}
      {tickets.length === 0 && <BottomReturnNavigationWrapper returnUrl="/" />}
    </Box>
  );
};

export default Tickets;

import { AlertColor, Box, useMediaQuery, useTheme } from '@mui/material';
import { useRef } from 'react';
import { getClientSetup } from '../modules/layout/selectors';
import { getDeviceMac } from '../utils/device';
import { useAppSelector } from '../utils/hooks';

interface LogoComponentProps {
  openSnackBar: (message: string, alertType: AlertColor) => void;
}

const LogoComponent = ({ openSnackBar }: LogoComponentProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const clientSetup = useAppSelector((x) => getClientSetup(x.layout));

  let macTaps = useRef<number>(0);
  const initMacDisplay = async () => {
    macTaps.current++;
    if (macTaps.current === 6) {
      console.log('macDisplay');
      macTaps.current = 0;
      const mac = await getDeviceMac();
      openSnackBar(`MAC: ${mac}`, 'success');
    }
  };
  const handleClientLogoClick = () => {
    initMacDisplay();
  };
  setInterval(() => {
    macTaps.current = 0;
  }, 1000 * 60);
  return <Box component="img" className="logo" src={clientSetup?.imgLogoUrl} alt={clientSetup?.clientDescription} onClick={() => handleClientLogoClick()} maxWidth={isSmallScreen ? '144px' : 'auto'} sx={{ objectFit: 'contain' }}></Box>;
};

export default LogoComponent;

// export class ClientSetupBase {
//   constructor(public imgLogoUrl: string, public clientDescription: string) {}
// }

export interface ClientSetup {
  imgLogoUrl: string;
  clientDescription: string;
}

export const PngsClientSetup = {
  imgLogoUrl: '/images/logos/pngs.png',
  clientDescription: 'Park Narodowy Gór Stołowych',
} as ClientSetup;

export const TpnClientSetup = {
  imgLogoUrl: '/images/logos/tpn.png',
  clientDescription: 'Tatrzański Park Narodowy',
} as ClientSetup;

export const MesseClientSetup = {
  imgLogoUrl: '/images/logos/messe.png',
  clientDescription: 'Messe Duesseldorf',
} as ClientSetup;

export const BgpnClientSetup = {
  imgLogoUrl: '/images/logos/bgpn.png',
  clientDescription: '',
} as ClientSetup;

export const AmplusClientSetup = {
  imgLogoUrl: '/images/logos/amplus.png',
  clientDescription: '',
} as ClientSetup;

export const MeanderClientSetup = {
  imgLogoUrl: '/images/logos/meander.png',
  clientDescription: '',
} as ClientSetup;

export const HawranClientSetup = {
  imgLogoUrl: '/images/logos/hawran.png',
  clientDescription: '',
} as ClientSetup;

export const StartveloClientSetup = {
  imgLogoUrl: '/images/logos/startvelo.png',
  clientDescription: '',
} as ClientSetup;

export const NoneClientSetup = {
  imgLogoUrl: '',
  clientDescription: '',
} as ClientSetup;

import { getTicketDetails } from '../modules/tickets/operations';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../utils/hooks';

const useQrReader = (): [(data: string) => void, boolean, string, boolean, (open: boolean) => void] => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openLoader, setOpenLoader] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleOpenError = (open: boolean) => {
    setOpenError(open);
  };

  const OpenTicketStatus = async (ticketId: string) => {
    setOpenLoader(true);
    const ticket = await dispatch(getTicketDetails(ticketId)).unwrap();
    console.log('Scanned ticket details: ' + JSON.stringify(ticket));
    setOpenLoader(false);
    if (ticket) {
      if (ticket.ticketWarnings.length > 0) {
        navigate(`/ticket/invalid/${ticketId}`);
        return;
      } else {
        navigate(`/ticket/valid/${ticketId}`);
        return;
      }
    } else {
      console.log(`Scanned invalid ticket ID: ${ticketId}`)
      setErrorMessage(t('common:scanner.wrongTicketNumberMessage').toString());
      setOpenError(true);
    }
  };

  const handleQrReader = async (data: string) => {
    if (data === undefined || data === null) {
      console.log('Wrong ticket number: ' + JSON.stringify(data));
      setErrorMessage(t('common:scanner.wrongTicketNumberMessage').toString());
      setOpenError(true);
      return;
    }
    let ticketId = '';
    data = data.replace('https://ticket.bergregions.pl/', '')
    if (data.startsWith('http') && data.indexOf('uuid')) {
      ticketId = data.substring(data.indexOf('uuid') + 5);
    } else {
      ticketId = data;
    }
    console.log('Scanned: ' + ticketId);
    if (ticketId) {
      await OpenTicketStatus(ticketId);
      return;
    }
  };

  return [handleQrReader, openLoader, errorMessage, openError, handleOpenError];
};

export default useQrReader;

import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { ReactNode, useEffect, useLayoutEffect } from 'react';
import { getAppSettings } from '../../modules/layout/operations';
import { getClientLayout, getIsLoadingDone } from '../../modules/layout/selectors';
import { fetchStats } from '../../modules/tickets/operations';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import PreLoader from '../PreLoader';
import TopAppBar, { ResponsiveToolbar } from '../TopAppBar';
import { MaxHeightContainer, theme } from './styles';
import { Layout as LayoutType } from '../../setup/Layout';
import { useLocation } from 'react-router-dom';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const dispatch = useAppDispatch();
  const isDone = useAppSelector((l) => getIsLoadingDone(l.layout));
  const layout = useAppSelector((x) => getClientLayout(x.layout));
  const location = useLocation();

  useLayoutEffect(() => {
    dispatch(getAppSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (layout == LayoutType.Desktop || location.pathname === '/tickets') {
      dispatch(fetchStats());
      const interval = setInterval(() => {
        dispatch(fetchStats());
      }, 30000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout, location.pathname]);

  const pageContent = (
    <>
      <TopAppBar />
      <ResponsiveToolbar />
      <MaxHeightContainer maxWidth={false}>{children}</MaxHeightContainer>
    </>
  );
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body: { backgroundColor: '#F2F2F2', height: '100%' },
            html: { backgroundColor: '#F2F2F2', height: '100%' },
          }}
        />
        {isDone ? pageContent : <PreLoader></PreLoader>}
      </ThemeProvider>
    </>
  );
};

export default Layout;

import { Box, CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RedButton, GreyButton } from '../../components/styled-components';
import { getInvoiceData } from '../../modules/basket/selectors';
import { PaymentState, terminalCodeDescriptions, TerminalState } from '../../modules/payment/const';
import { CreateConfirmPaymentRequestModel } from '../../modules/payment/helpers';
import { PaymentType, OrderTicket, OrderProduct, PaymentProcessingStatus } from '../../modules/payment/models';
import { confirmPayment } from '../../modules/payment/operations';
import { getOrderStatus } from '../../modules/payment/selectors';
import { useAppDispatch, useAppReturnLink, useAppSelector } from '../../utils/hooks';
import { printInvoice, startPayment } from '../../utils/terminal';
import WizardSteps from './WizardSteps';
import { clearBasket } from '../../modules/basket/slice';
import { clearOrderStatus } from '../../modules/payment/slice';
import usePrimaryModeReset from '../../hooks/usePrimaryModeReset';
import HardwareOperationDialog from '../summary/HardwareOperationDialog';

const initialPaymentProcessingStatus = { terminalState: undefined } as PaymentProcessingStatus;

const Terminal = () => {
  const { t } = useTranslation(['common']);
  const [, setPaymentState] = useState<PaymentState>();
  const [, setCustomMessage] = useState<string>('');
  const [paymentProcessingStatus, setPaymentProcessingStatus] =
    useState<PaymentProcessingStatus>(initialPaymentProcessingStatus);
  const [errorCount, setErrorCount] = useState<number>(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [terminalStatusResponseDescription, setTerminalStatusResponseDescription] = useState<string>('');
  const orderStatus = useAppSelector((state) => getOrderStatus(state.payment));
  const invoiceData = useAppSelector((state) => getInvoiceData(state.basket));
  const theme = useTheme();
  const isDownSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isDownMDScreen = useMediaQuery(theme.breakpoints.down('md'));
  const primaryLink = useAppReturnLink();
  const [resettingRequired, restartAutoReset] = usePrimaryModeReset();

  const [hardwareOperationInProgress, setHardwareOperationInProgress] = useState(false);

  useEffect(() => {
    if (window.BtApp) {
      window.BtApp.updatePaymentStatus = (terminalCode: number, defaultMessage: string) => {
        const terminalCodeInt = Number(terminalCode);
        console.log(
          `[TERMINAL] updatePaymentStatus:`,
          JSON.stringify({ terminalCode, terminalCodeInt, defaultMessage })
        );

        setPaymentState(PaymentState.PENDING);
        setLoading(true);
        setCustomMessage(defaultMessage || '');
        // setTerminalCode(Number(response));
        setTerminalStatusResponseDescription(terminalCodeDescriptions.get(terminalCodeInt) ?? defaultMessage);
        if (resettingRequired) {
          restartAutoReset();
        }
      };

      window.BtApp.paymentFinished = (terminalCode: number, defaultMessage: string, transactionId: string) => {
        const terminalCodeInt = Number(terminalCode);
        console.log(
          `[TERMINAL] paymentFinished:`,
          JSON.stringify({ terminalCode, terminalCodeInt, defaultMessage, transactionId })
        );

        setCustomMessage(defaultMessage || '');
        setTerminalStatusResponseDescription(terminalCodeDescriptions.get(terminalCodeInt) ?? defaultMessage);

        switch (terminalCodeInt) {
          case 0:
            setPaymentState(PaymentState.SUCCESS);
            console.log('[TERMINAL] paymentFinished: Payment status ' + JSON.stringify(orderStatus));
            if (orderStatus) {
              dispatch(
                confirmPayment(
                  CreateConfirmPaymentRequestModel(
                    orderStatus,
                    PaymentType.CREDIT_CARD,
                    orderStatus?.totalAmount,
                    transactionId
                  )
                )
              );
              startPrinting(orderStatus.orderId, orderStatus.tickets, orderStatus.products);
            }

            break;
          default:
            setPaymentState(PaymentState.ERROR);
            setPaymentProcessingStatus({ terminalState: TerminalState.repeat } as PaymentProcessingStatus);
            setErrorCount(errorCount + 1);
            break;
        }
        setLoading(false);
      };
    }
    startProcessingPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startPrinting = async (orderId: string, tickets: OrderTicket[], products: OrderProduct[]): Promise<void> => {
    setHardwareOperationInProgress(true)
    await new Promise((resolve) => setTimeout(resolve, 0));

    console.log('startPrinting');
    const printState = await printInvoice(
      orderId ?? '',
      tickets ?? [],
      products ?? [],
      invoiceData?.nip,
      PaymentType.CREDIT_CARD,
    );
    console.log('[Terminal] Print status ' + printState);
    setHardwareOperationInProgress(false)
    navigate('/payment_wizard/terminal/confirmation');
  };

  const startProcessingPayment = async (): Promise<void> => {
    setLoading(true);
    setTerminalStatusResponseDescription('');
    setPaymentProcessingStatus(initialPaymentProcessingStatus);
    try {
      const paymentProcessingStatus = await startPayment(orderStatus?.totalAmount ?? 0, orderStatus?.orderId ?? '');

      setPaymentProcessingStatus(() => paymentProcessingStatus);

      if (
        paymentProcessingStatus.terminalState !== TerminalState.PAYMENT_STARTED &&
        paymentProcessingStatus.terminalState !== TerminalState.PAYMENT_STARTED_OK
      ) {
        setPaymentState(PaymentState.ERROR);
        setErrorCount(errorCount + 1);
        setLoading(false);
      } else {
        setPaymentState(PaymentState.PENDING);
        setErrorCount(0);
      }
    } catch (e) {
      setTimeout(() => {
        setLoading(false);
        setPaymentState(PaymentState.ERROR);
        setPaymentProcessingStatus({ terminalState: TerminalState.exception } as PaymentProcessingStatus);
        setErrorCount(errorCount + 1);
        console.error('Failed startPayment', JSON.stringify(e));
      }, 2000);
    }
  };

  const handleRepeatPayment = async () => {
    console.log('Repeat payment button clicked');
    await startProcessingPayment();
  };

  const handleDropPayment = async () => {
    dispatch(clearBasket());
    dispatch(clearOrderStatus());
    navigate(primaryLink);
  };

  const getTerminalStateMessage = (paymentProcessingStatus: PaymentProcessingStatus): string => {
    switch (paymentProcessingStatus.terminalState) {
      case TerminalState.PAYMENT_STARTED:
        return t('common:terminalStates.paymentStarted');
      case TerminalState.PAYMENT_STARTED_OK:
        return t('common:terminalStates.paymentStarted');
      case TerminalState.repeat:
        return t('common:terminalStates.repat');
      case TerminalState.cancel:
        return t('common:terminalStates.cancel');
      case TerminalState.NO_CONNECTION:
        return t('common:terminalStates.noConnection', {
          ip: paymentProcessingStatus.ip ?? '',
          port: paymentProcessingStatus.port ?? '',
        });
      case TerminalState.AMOUNT_INVALID:
        return t('common:terminalStates.amountInvalid');
      case TerminalState.INTERNAL_ERROR:
        return t('common:terminalStates.internalError');
      case TerminalState.exception:
        return t('common:terminalStates.exception');
      default:
        console.warn('Not supported terminal state: ' + paymentProcessingStatus.terminalState);
        return '';
    }
  };

  return (
    <>
      <WizardSteps activeStep={1}>
        <Stack direction={'column'} justifyContent={'center'} spacing={3} sx={{ pt: 3 }}>
          <Typography align="center" sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '36px' }}>
            {paymentProcessingStatus && getTerminalStateMessage(paymentProcessingStatus)}
          </Typography>
          <Stack direction={'column'} spacing={3} justifyContent={'center'} alignItems={'center'}>
            {loading && <CircularProgress color="secondary" />}
            <Typography align="center" sx={{ fontSize: '26px', lineHeight: '45px' }}>
              {terminalStatusResponseDescription}
            </Typography>
          </Stack>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            {errorCount > 0 ? (
              <Box
                component="img"
                src="/images/terminal_error.png"
                sx={{ height: isDownMDScreen ? '130px' : 'auto', maxHeight: '25vh' }}
              ></Box>
            ) : (
              <Box
                component="img"
                src="/images/terminal_payment.png"
                sx={{ height: isDownMDScreen ? '130px' : 'auto' }}
              ></Box>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            {paymentProcessingStatus.terminalState !== TerminalState.PAYMENT_STARTED &&
              paymentProcessingStatus.terminalState !== TerminalState.PAYMENT_STARTED_OK &&
              !loading &&
              errorCount > 0 && (
                <Stack direction="column" alignItems={'center'} spacing={2}>
                  <Stack direction={isDownSmallScreen ? 'column' : 'row'} spacing={2}>
                    <GreyButton disabled={loading} onClick={handleRepeatPayment}>
                      <Typography>{t('common:payments.retryPaymentButton').toString()}</Typography>
                    </GreyButton>
                    <RedButton disabled={loading} onClick={handleDropPayment}>
                      <Typography>{t('common:buttons.complete').toString()}</Typography>
                    </RedButton>
                  </Stack>
                </Stack>
              )}
          </Grid>
        </Stack>
      </WizardSteps>
      <Suspense>
        <HardwareOperationDialog open={ hardwareOperationInProgress } />
      </Suspense>
    </>
  );
};

export default Terminal;

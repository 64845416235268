import { Divider, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RedButton } from '../../components/styled-components';
import { useNavigate } from 'react-router-dom';

interface BottomReturnNavigationWrapperProps {
  returnUrl: string;
}

const BottomReturnNavigationWrapper = ({ returnUrl }: BottomReturnNavigationWrapperProps) => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMDScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Divider sx={{ borderBottom: '1px dashed #949494', my: 3 }}></Divider>
      <Grid container sx={{ pb: 1 }}>
        <Grid item xs={12} md={6} justifyContent={isMDScreen ? 'right' : 'center'} container>
          <Stack direction="row" spacing={2} alignItems="center">
            {isMDScreen && (
              <RedButton onClick={() => navigate(returnUrl)}>
                <>{t('common:buttons.back')}</>
              </RedButton>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default BottomReturnNavigationWrapper;

import { Divider, Grid, Stack, Typography, styled, useMediaQuery, useTheme, Box } from '@mui/material';
import TicketList from './summary/TicketList';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { getBasketSummary } from '../modules/basket/selectors';
import { AddCompanyDataButton, GreyButton, PaymentButton, RedButton } from '../components/styled-components';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getReturnUrl, toWithReturnParam } from '../utils/navigationHelper';
import { clearBasket } from '../modules/basket/slice';
import { useSinglePaymentType } from '../modules/payment/hooks';
import { Suspense, useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../components/PageHeader/PageHeader';
import PrinterConnectingDialog from './summary/PrinterConnectingDialog';
import useValidateSettingsAndPay, { ConnectingStatus } from '../hooks/useValidateSettingsAndPay';
import HardwareOperationDialog from './summary/HardwareOperationDialog';

const PrinterWarningDialog = React.lazy(() => import('./summary/PrinterWarningDialog'));

const PlusIcon = styled(AddCircleIcon)({
  fontSize: '2rem',
  width: '35px',
  '&>:nth-of-type(1)': {
    fontSize: '2rem',
  },
});

const Summary = () => {
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const basketSummary = useAppSelector((state) => getBasketSummary(state.basket));
  const [paymentButtonText, setPaymentButtonText] = useState<string>('Zapłać');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [singlePaymentType] = useSinglePaymentType();
  const theme = useTheme();
  const isDownMDScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [validateSettingsAndPay, printerConnectingState, hardwareOperationInProgress, _, resetState] = useValidateSettingsAndPay();

  const handlePaymentClick = async (): Promise<void> => {
    await new Promise((resolve) => setTimeout(resolve, 0));

    await validateSettingsAndPay();
  };

  const handleAddCompanyData = () => {
    navigate(toWithReturnParam(location, '/summary/company-details'));
  };

  const handleReturnClicked = () => {
    const url = getReturnUrl(searchParams, '/tickets');
    if (url === '/') {
      dispatch(clearBasket());
    }
    navigate(url);
  };

  useEffect(() => {
    setPaymentButtonText(singlePaymentType ? t('common:buttons.payment') : t('common:buttons.pay'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singlePaymentType]);

  return (
    <Stack direction={'column'} justifyContent={'space-between'} sx={{ py: 2, maxHeight: '100%' }}>
      <PageHeader title={t('common:summary.summary')}></PageHeader>

      <Grid container>
        <Grid item xs={12}>
          <TicketList></TicketList>
          <Divider sx={{ mb: 2 }}></Divider>
        </Grid>
      </Grid>

      <Box>
        <Grid container>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ fontSize: '25px', display: 'block', fontWeight: '400' }}>
                <>{t('common:summary.ticketCount')}:</>
              </Typography>
              <Typography sx={{ fontSize: '25px', display: 'block', fontWeight: '600', color: '#9464FC' }}>
                {basketSummary.quantity} <>{t('common:tickets.units')}</>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Stack direction="row" spacing={1}>
              <Typography sx={{ fontSize: '34px', fontWeight: 600 }}>
                <>{t('common:tickets.sum')}:</>
              </Typography>
              <Typography sx={{ fontSize: '34px', fontWeight: 600, color: '#9464FC' }}>
                {basketSummary.amount.toFixed(2)} {basketSummary.currency}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Stack
          direction={'row'}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          spacing={2}
          sx={{ mt: 4, pb: 2 }}
        >
          {!isDownMDScreen && (
            <RedButton onClick={handleReturnClicked}>
              <>{t('common:buttons.back')}</>
            </RedButton>
          )}
          <Stack
            direction={isDownMDScreen ? 'column' : 'row'}
            spacing={2}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <AddCompanyDataButton onClick={handleAddCompanyData} sx={{ alignSelf: 'center' }} startIcon={<PlusIcon />}>
              <>{t('common:summary.companyData')}</>
            </AddCompanyDataButton>
            <PaymentButton onClick={handlePaymentClick} sx={{ alignSelf: 'center' }}>
              {paymentButtonText}
            </PaymentButton>
          </Stack>
        </Stack>
      </Box>

      <Suspense>
        <PrinterWarningDialog
          open={printerConnectingState === ConnectingStatus.Error}
          handleClose={() => resetState()}
        ></PrinterWarningDialog>
      </Suspense>
      <PrinterConnectingDialog open={printerConnectingState === ConnectingStatus.Pending} />
      <HardwareOperationDialog open={ hardwareOperationInProgress } />
    </Stack>
  );
};

export default Summary;

import { Box, CircularProgress, Dialog, DialogTitle, Stack, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

interface HardwareOperationDialogProps {
  open: boolean;
}

const HardwareOperationDialog = ({ open }: HardwareOperationDialogProps) => {
  const { t } = useTranslation(['common']);

  const [operationMessage, setOperationMessage] = useState<string>('');
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchHardwareStatus = () => {
      if (window.BtDevice && open) {
        const status = window.BtDevice.getHardwareStatus();
        console.log('[HardwareOperationDialogProps] fetchHardwareStatus current status: ' + status);
        flushSync(() => setOperationMessage(status));
      }
    };

    if (open) {
      intervalRef.current = setInterval(fetchHardwareStatus, 100);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [open]);

  return (
    <Dialog open={open} sx={{ textAlign: 'center' }}>
      <DialogTitle>{t('common:hardware.operation.title')}</DialogTitle>
      <Stack direction={'column'} spacing={3} sx={{ p: 3 }} justifyContent={'center'} alignItems="center">
        <Typography>{operationMessage || ''}</Typography>
        <CircularProgress disableShrink />
      </Stack>
    </Dialog>
  );
};

export default HardwareOperationDialog;

import { PrimaryMode } from '../utils/device';
import i18n from '../i18n/i18n';
import { clearBasket } from '../modules/basket/slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../utils/hooks';
import { getAppDetails, getPrimaryMode } from '../modules/layout/selectors';
import { useEffect, useMemo } from 'react';

let timeout: ReturnType<typeof setTimeout>;

const usePrimaryModeReset = (): [boolean, () => void] => {
  const navigate = useNavigate();
  const appDetails = useAppSelector((l) => getAppDetails(l.layout));
  const dispatch = useAppDispatch();
  const location = useLocation();
  const primaryMode = useAppSelector((x) => getPrimaryMode(x.layout));

  let newLocation = '';

  const resettingRequired = useMemo(() => {
    if (primaryMode == undefined && primaryMode == PrimaryMode.None) {
      return false;
    }
    return true;
  }, [primaryMode]);

  useEffect(() => {
    console.log('usePrimaryModeReset - primaryMode', primaryMode);
    if (resettingRequired && location?.pathname) {
      console.log('Set new location');
      newLocation = location.pathname;
      restartAutoReset();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [location.pathname, primaryMode]);

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      console.log(`[usePrimaryModeReset] Trying to reset to primary mode: ${primaryMode} on path: ${newLocation}`);
      if (primaryMode == PrimaryMode.Sales && newLocation != '/tickets') {
        console.log('reset to tickets');
        i18n.changeLanguage(appDetails.language);
        navigate('/tickets');
        dispatch(clearBasket());
      }
      if (primaryMode == PrimaryMode.TicketScan && newLocation != '/scan') {
        console.log('[usePrimaryModeReset] reset to scan');
        i18n.changeLanguage(appDetails.language);
        navigate('/scan');
        dispatch(clearBasket());
      }
    }, 1000 * 2 * 60);
  };

  return [resettingRequired, restartAutoReset];
};

export default usePrimaryModeReset;
